<template>
  <div>
    <div class="banner">
      <h1>Install PostEngine</h1>
    </div>
    <div class="content">
      <router-view v-model="selectedProperties" :token="token" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInstall',
  beforeRouteEnter (to, from, next) {
    const token = to.hash.substring(1)
    if (token) {
      next(vm => { vm.token = token })
    } else {
      next({ name: 'Page404' })
    }
  },
  data () {
    return {
      token: '',
      selectedProperties: []
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 25vh;
  background-color: $info;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  padding: 2em;
  height: 75vh;
}
</style>
